<!--
 * @Author: wh
 * @Date: 2020-07-21 11:59:06
 * @LastEditTime: 2022-09-02 16:31:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BusinessPlatform\src\views\salesBusiness\retailBusiness\retailBusinessForm.vue
-->
<template>
    <div class="fomeStyle_dy retailBusinessForm" v-loading="loading">
        <div class="table-container">
            <table class="custom-table">
                <thead>
                    <tr>
                        <th colspan="4">
                            <span>零售业务信息表</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="column-tit">
                            业务编号:
                        </td>
                        <td>
                            <div class="el_left" style="width:calc(100% - 1.4rem);">
                                <template v-if="formData.is_customize_number">
                                    <el-input placeholder="请输入自定义编号" v-model="customMumber">
                                        <template slot="prepend">
                                            【{{ numberPrefix }}】
                                        </template>
                                    </el-input>
                                </template>
                                <template v-else>
                                    <span class="businessNumber">{{ formData.contract_number }}</span>
                                </template>
                            </div>
                            <div class="el_right" style="margin-right: 0.2rem;">
                                <el-checkbox
                                    v-model="formData.is_customize_number"
                                    @change="customMumberChange"
                                    :disabled="vueFormParams.IsRead"
                                >
                                    自定义编号
                                </el-checkbox>
                            </div>
                        </td>
                        <td class="column-tit">
                            纸质合同编号:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.paper_contract_number"
                                :disabled="vueFormParams.IsRead"
                            >
                            </el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            登记时间:
                        </td>
                        <td colspan="3">
                            <el-date-picker
                                v-model="formData.register_time"
                                type="datetime"
                                placeholder="选择日期"
                                :disabled="vueFormParams.IsRead"
                            >
                            </el-date-picker>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            工程名称:
                        </td>
                        <td colspan="3">
                            <el-input
                                placeholder="请输入"
                                v-model="formData.engineering_name"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            工程类型:
                        </td>
                        <td>
                            <el-select
                                v-model="formData.engineering_type_name"
                                placeholder="请选择"
                                @change="engineeringTypeChange"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-option
                                    v-for="k in engineeringTypes"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td class="column-tit">
                            标段:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.section"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            工程地址:
                        </td>
                        <td colspan="2">
                            <el-input
                                readonly
                                placeholder="请选择定位"
                                v-model="formData.engineering_location"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                        <td>
                            <el-button type="primary" size="small" @click="setLocation">
                                定位
                            </el-button>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            供方单位:
                        </td>
                        <td colspan="3">
                            <el-input
                                placeholder="请输入"
                                v-model="formData.org_name"
                                readonly
                                :disabled="vueFormParams.IsRead"
                                @focus="selectSupplierUnit"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            客户名称:
                        </td>
                        <td colspan="2">
                            <el-autocomplete
                                value="id"
                                :debounce="0"
                                value-key="name"
                                class="inline-input"
                                placeholder="请输入"
                                :trigger-on-focus="false"
                                v-model="formData.client_name"
                                :fetch-suggestions="querySearch"
                                :disabled="vueFormParams.IsRead"
                                @select="handleSelect"
                            ></el-autocomplete>
                        </td>
                        <td>
                            <el-button type="primary" size="small" @click="enterpriseInspection">
                                企查查
                            </el-button>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            客户类型:
                        </td>
                        <td>
                            <el-select
                                v-model="formData.client_type"
                                placeholder="请选择"
                                @change="clientTypeChange"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-option
                                    v-for="k in clientTypes"
                                    :key="`client_type_${k.code}`"
                                    :label="k.name"
                                    :value="k.code"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            统一社会信用代码/身份证号:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.client_uscc"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            客户联系人:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.client_contact_name"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            联系电话:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.client_contact_phone"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            业务员:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.salesman_name"
                                @focus="selectSalesman('ywy')"
                                :disabled="vueFormParams.IsRead"
                            >
                                <i slot="append" class="iconfont icon05qunzufill"></i>
                            </el-input>
                        </td>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            业务员电话:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.salesman_phone"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            服务人员:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.waiter_name"
                                @focus="selectSalesman('fwy')"
                                :disabled="vueFormParams.IsRead"
                            >
                                <i slot="append" class="iconfont icon05qunzufill"></i>
                            </el-input>
                        </td>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            服务人员电话:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.waiter_phone"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            供货站点:
                        </td>
                        <td>
                            <el-select
                                v-model="formData.supply_site_name"
                                placeholder="请选择"
                                @change="supplySiteChange"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-option
                                    v-for="k in supplySites"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            销售区域:
                        </td>
                        <td>
                            <el-select
                                v-model="formData.area_name"
                                placeholder="请选择"
                                @change="areaNameChange"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-option
                                    v-for="k in saleAreas"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            方量（m³）:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.projected_capacity"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            单价（含运费）:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.price"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            金额（元）:
                        </td>
                        <td colspan="3">
                            <el-input
                                placeholder="请输入"
                                v-model="formData.amount_money"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            支付方式:
                        </td>
                        <td>
                            <el-select
                                v-model="formData.payment_mode_name"
                                placeholder="请选择"
                                @change="paymentModeChange"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-option
                                    v-for="k in paymentModes"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            是否到账:
                        </td>
                        <td>
                            <el-radio-group
                                v-model="formData.is_payment"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-radio :label="false">
                                    未到账
                                </el-radio>
                                <el-radio :label="true">
                                    已到账
                                </el-radio>
                            </el-radio-group>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            强度等级:
                        </td>
                        <td>
                            <el-select
                                v-model="formData.strength_grade_name"
                                placeholder="请选择"
                                @change="strengthGradeChange"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-option
                                    v-for="k in strengthGrades"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td class="column-tit">
                            附加型号:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.additional_model_name"
                                @focus="selectAdditional"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            施工部位:
                        </td>
                        <td>
                            <el-select
                                v-model="formData.casting_parts_name"
                                placeholder="请选择"
                                @change="castingPartsChange"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-option
                                    v-for="k in castingParts"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            浇筑方式:
                        </td>
                        <td>
                            <el-select
                                v-model="formData.pouring_way_name"
                                placeholder="请选择"
                                @change="pouringWayChange"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-option
                                    v-for="k in pouringWays"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            泵车类型:
                        </td>
                        <td>
                            <el-select
                                v-model="formData.pump_truck_name"
                                placeholder="请选择"
                                @change="pumpTrucksChange"
                                :disabled="pumpTruckTypeDisable || vueFormParams.IsRead"
                            >
                                <el-option
                                    v-for="k in pumpTrucks"
                                    :key="k.code"
                                    :label="k.name"
                                    :value="k.name"
                                >
                                </el-option>
                            </el-select>
                        </td>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            泵送单价:
                        </td>
                        <td>
                            <div class="exclusiveRow">
                                <el-input
                                    placeholder="请输入"
                                    v-model="formData.pump_supplement_price"
                                    class="line-input"
                                    :disabled="volumePriceDisable||vueFormParams.IsRead"
                                ></el-input>
                                <span>元/m³</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            泵送补充模式:
                        </td>
                        <td colspan="1">
                            <el-radio-group
                                v-model="formData.pump_complementary_model"
                                :disabled="volumePriceDisable || vueFormParams.IsRead"
                            >
                                <el-radio :label="1">
                                    补方
                                </el-radio>
                                <el-radio :label="2">
                                    补固定金额
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <template v-if="formData.pump_complementary_model===1">
                            <td colspan="2">
                                <div class="exclusiveRow">
                                    <span>不足</span>
                                    <el-input
                                        placeholder="请输入"
                                        v-model="formData.pump_insufficient"
                                        type="number"
                                        class="line-input"
                                        :disabled="volumePriceDisable|| vueFormParams.IsRead"
                                    ></el-input>
                                    <span>方，按</span>
                                    <el-input
                                        placeholder="请输入"
                                        v-model="formData.pump_add_quantity"
                                        type="number"
                                        class="line-input"
                                        :disabled="volumePriceDisable|| vueFormParams.IsRead"
                                    ></el-input>
                                    <span>方结算</span>
                                </div>
                            </td>
                        </template>
                        <template v-if="formData.pump_complementary_model===2">
                            <td colspan="2">
                                <div class="exclusiveRow">
                                    <span>不足</span>
                                    <el-input
                                        placeholder="请输入"
                                        class="price-input"
                                        v-model="formData.pump_insufficient"
                                        :disabled="volumePriceDisable|| vueFormParams.IsRead"
                                        type="number"
                                    ></el-input>
                                    <span>方，收取</span>
                                    <el-input
                                        placeholder="请输入"
                                        class="price-input"
                                        v-model="formData.pump_add_money"
                                        :disabled="volumePriceDisable|| vueFormParams.IsRead"
                                        type="number"
                                    ></el-input>
                                    <span>元。</span>
                                </div>
                            </td>
                        </template>
                    </tr>

                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            施工部位描述:
                        </td>
                        <td colspan="3">
                            <el-input
                                placeholder="请输入"
                                v-model="formData.casting_parts_desc"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            塌落度:
                        </td>
                        <td>
                            <div class="exclusiveRow">
                                <el-input
                                    placeholder="请输入"
                                    v-model="formData.slumps"
                                    :disabled="vueFormParams.IsRead"
                                    class="slump"
                                ></el-input>
                                ±
                                <el-input
                                    placeholder="请输入"
                                    v-model="formData.slumps_float"
                                    :disabled="vueFormParams.IsRead"
                                    class="slump"
                                ></el-input>
                            </div>
                        </td>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            用料时间:
                        </td>
                        <td>
                            <el-date-picker
                                v-model="formData.used_materials_time"
                                type="datetime"
                                placeholder="选择日期"
                                :disabled="vueFormParams.IsRead"
                                value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            运距（km）:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.haul_distance"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            运费:
                        </td>
                        <td>
                            <el-input
                                placeholder="请输入"
                                v-model="formData.freight"
                                class="freightPrice"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            <span class="NotAllowNullCss">*</span>
                            基础运费:
                        </td>
                        <td colspan="3">
                            <div class="exclusiveRow">
                                <el-input
                                    placeholder="请输入"
                                    v-model="formData.freight_supplement_price"
                                    class="freightPrice"
                                    :disabled="vueFormParams.IsRead"
                                ></el-input>
                                <span>元/m³</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            运费补充模式:
                        </td>
                        <td>
                            <el-radio-group
                                v-model="formData.freight_complementary_model"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-radio :label="1">
                                    补方
                                </el-radio>
                                <el-radio :label="2">
                                    补固定金额
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <template v-if="formData.freight_complementary_model===1">
                            <td colspan="2">
                                <div class="exclusiveRow">
                                    <span>每车不足</span>
                                    <el-input
                                        placeholder="请输入"
                                        v-model="formData.freight_insufficient"
                                        type="number"
                                        class="line-input"
                                        :disabled="vueFormParams.IsRead"
                                    ></el-input>
                                    <span>方，按</span>
                                    <el-input
                                        placeholder="请输入"
                                        v-model="formData.freight_add_quantity"
                                        class="line-input"
                                        :disabled="vueFormParams.IsRead"
                                        type="number"
                                    ></el-input>
                                    <span>m³收取运费。</span>
                                </div>
                            </td>
                        </template>
                        <template v-if="formData.freight_complementary_model===2">
                            <td colspan="2">
                                <div class="exclusiveRow">
                                    <span>单次方不足</span>
                                    <el-input
                                        placeholder="请输入"
                                        class="price-input"
                                        v-model="formData.freight_insufficient"
                                        type="number"
                                        :disabled="vueFormParams.IsRead"
                                    ></el-input>
                                    <span>方,按实际方量价格结算后加收</span>
                                    <el-input
                                        placeholder="请输入"
                                        class="price-input"
                                        v-model="formData.freight_add_money"
                                        :disabled="vueFormParams.IsRead"
                                        type="number"
                                    ></el-input>
                                    <span>元。</span>
                                </div>
                            </td>
                        </template>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            润泵砂浆价格:
                        </td>
                        <td colspan="1">
                            <el-radio-group
                                v-model="formData.mortar_price_type"
                                :disabled="vueFormParams.IsRead"
                            >
                                <el-radio :label="0">
                                    与产品同价
                                </el-radio>
                                <el-radio :label="1">
                                    与产品不同价
                                </el-radio>
                            </el-radio-group>
                        </td>
                        <template v-if="formData.mortar_price_type===1">
                            <td colspan="2">
                                <div class="exclusiveRow">
                                    <span>单次方不足</span>
                                    <el-input
                                        placeholder="请输入"
                                        class="price-input"
                                        v-model="formData.mortar_price_percent"
                                        :disabled="vueFormParams.IsRead"
                                        type="number"
                                    ></el-input>
                                    <span>%产品价</span>
                                    <el-input
                                        placeholder="请输入"
                                        class="price-input"
                                        v-model="formData.mortar_price"
                                        :disabled="vueFormParams.IsRead"
                                        type="number"
                                    ></el-input>
                                    <span>元。</span>
                                </div>
                            </td>
                        </template>
                    </tr>
                    <tr>
                        <td class="column-tit">
                            备注:
                        </td>
                        <td colspan="3">
                            <el-input
                                placeholder="请输入"
                                v-model="formData.remarks"
                                :disabled="vueFormParams.IsRead"
                            ></el-input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        vueFormParams: {
            type: [Object],
        },
    },
    data() {
        return {
            formData: {
                freight_supplement_price: 0,
                client_type: '',
                client_type_name: '',
            },
            types: [],
            // 自定义编号前缀
            numberPrefix: '',
            // 自定义编号
            customMumber: '',
            // 工程类型
            engineeringTypes: [],
            // 客户类型
            clientTypes: [],
            // 支付方式
            paymentModes: [],
            // 施工部位
            castingParts: [],
            // 浇筑方式
            pouringWays: [],
            // 泵车类型
            pumpTrucks: [],
            // 供货站点
            supplySites: [],
            // 销售区域
            saleAreas: [],
            // 强度等级
            strengthGrades: [],
            // 流程信息模板
            businessInfo: {},
            // 泵送单价是否可用
            volumePriceDisable: true,
            // 泵车类型是否可用
            pumpTruckTypeDisable: false,
            // 合同编号
            contractId: '',
            // 加载
            loading: false,
            // 选择人员类型（‘ywy’：业务员，‘fwy’；服务人员）
            selectSalesmanType: '',

            state2: '',
        };
    },
    watch: {},
    computed: {},
    created() {
        // 自定义编号前缀
        this.numberPrefix = this.$takeTokenParameters('TenantCode');
    },
    mounted() {
        if (this.vueFormParams.formId) {
            this.vueFormParams.PKValues = this.vueFormParams.formId;
        }
        if (this.vueFormParams.PKValues) {
            // 修改
            this.contractId = this.vueFormParams.PKValues;
            this.loading = true;
            this.getRetailDetail();
        } else {
            // 新增
            this.initAdd();
        }
        this.getCategory();
        this.getStationList();
        this.getSaleAreaList();
        this.getStrengthGrade();
    },
    methods: {
        // 新增初始化
        initAdd() {
            const currentTime = util.currentTimeFormat('yyyy-MM-dd HH:mm:ss');
            this.formData.register_time = currentTime;
            this.formData.salesman_id = this.$takeTokenParameters('Uid');
            this.formData.salesman_name = this.$takeTokenParameters('Name');
            this.formData.salesman_phone = this.$takeTokenParameters('MobilePhone');
            this.formData.freight_supplement_price = 35;
            // this.getBusinessInstance();
        },
        // 拉取合同详情
        async getRetailDetail() {
            const res = await this.getRetailDetailApi();
            if (res) {
                this.loading = false;
                this.formData = res;
                const value = res.pouring_way_name;
                if (['汽车泵', '车载泵', '固定泵'].indexOf(value) > -1) {
                    this.volumePriceDisable = false;
                } else {
                    this.volumePriceDisable = true;
                    this.pump_supplement_price = 0;
                }
                if (['自卸', '吊斗', '转运', '工地汽车泵', '工地车载泵', '工地固定泵'].indexOf(value) > -1) {
                    this.pumpTruckTypeDisable = true;
                    this.formData.pump_truck_name = '';
                    this.formData.pump_truck = '';
                } else {
                    this.pumpTruckTypeDisable = false;
                }
            } else {
                this.loading = false;
            }
        },
        // 保存
        async save(successCallBack, errorCallBack) {
            const verification = this.verifyCharacter();
            if (verification) {
                this.$message({
                    message: '业务编号含有特殊字符,请修改编号。',
                    type: 'warning',
                });
                errorCallBack();
            } else {
                if (this.formData.is_customize_number) {
                    this.formData.contract_number = '【' + this.numberPrefix + '】' + this.customMumber;
                } else if ((this.formData.contract_number || '') === '') { // 没有业务编号-新增，有编号-编辑
                    const res = await this.getAutoCodeApi();
                    this.formData.contract_number = res.AutoCodeValue;
                    this.formData.auto_index = `${res.AutoIndex}`;
                    this.$forceUpdate();
                }
                const amount_money = this.formData.amount_money || 0;
                const price = this.formData.price;
                const projected_capacity = this.formData.projected_capacity;
                let money = 0;
                if (price && projected_capacity) {
                    money = Number(price) * Number(projected_capacity);
                }
                if (amount_money || amount_money === 0) {
                    if (money > Number(amount_money) ) {
                        this.$message.warning('金额小于单价乘以方量，不能保存！');
                        errorCallBack();
                        return;
                    }
                }
                if (this.contractId) {
                    this.$axios.put('/interfaceApi/sale/contract_manager/modify_retail', this.formData)
                        .then(res => {
                            if (res) {
                                successCallBack(res);
                            }
                        }).catch(error => {
                            this.$message.error({
                                showClose: true,
                                dangerouslyUseHTMLString: true,
                                message: error.ErrorCode.Message,
                            });
                            errorCallBack();
                        });
                } else {
                    this.formData.business_instance_id = this.vueFormParams.businessInstanceId;
                    this.$axios.post('/interfaceApi/sale/contract_manager/add_retail', this.formData)
                        .then(res => {
                            if (res) {
                                this.contractId = res;
                                successCallBack(res);
                            }
                        }).catch(error => {
                            this.$message.error({
                                showClose: true,
                                dangerouslyUseHTMLString: true,
                                message: error.ErrorCode.Message,
                            });
                            errorCallBack();
                        });
                }
            }
        },
        // 获取流程实例
        async getBusinessInstance() {
            const res = await this.getBusinessInstanceApi();
            if (res) {
                this.businessInfo = res;
            }
        },
        // 选择业务员
        selectSalesman(type) {
            this.selectSalesmanType = type;
            this.$toast({
                title: true,
                text: '选择人员',
                type: 'eject',
                width: '10rem',
                height: '80%',
                t_url: 'selectUser/selectUser',
                viewPosition: 'components',
                extr: {
                    isCurrentDept: true,
                    sureCallBack: this.salesmanCallBack,
                },
            });
        },
        // 选择业务员-回调
        salesmanCallBack(data) {
            if (this.selectSalesmanType === 'ywy') {
                this.formData.salesman_id = data.User_Id;
                this.formData.salesman_name = data.User_Name;
                this.formData.salesman_phone = data.Mobile;
            } else if (this.selectSalesmanType === 'fwy') {
                this.formData.waiter_id = data.User_Id;
                this.formData.waiter_name = data.User_Name;
                this.formData.waiter_phone = data.Mobile;
            }
            this.$forceUpdate();
        },
        // 选取附加型号
        selectAdditional() {
            this.$toast({
                title: true,
                text: '选择附加型号',
                type: 'eject',
                width: '14.3rem',
                height: '90%',
                t_url: 'salesBusiness/selectModel/index',
                extr: {
                    sureCallback: this.selectAdditionalCallback,
                },
            });
        },
        // 选取附加型号-回调
        selectAdditionalCallback(list) {
            let additional_model_codes = '';
            let additional_models = '';
            list.forEach(element => {
                additional_model_codes += element.smallclass_code + ',';
                additional_models += element.smallclass_name + '+';
            });
            additional_model_codes = additional_model_codes.substr(0, additional_model_codes.length - 1);
            additional_models = additional_models.substr(0, additional_models.length - 1);
            this.formData.additional_model = additional_model_codes;
            this.formData.additional_model_name = additional_models;
            this.$forceUpdate();
        },
        // 定位
        setLocation() {
            this.$toast({
                title: true,
                text: '定位',
                type: 'eject',
                width: '1000px',
                height: '80%',
                t_url: 'setLocation/location',
                viewPosition: 'components',
                extr: {
                    position: [this.formData.x_coordinate_point, this.formData.y_coordinate_point],
                    address: this.formData.engineering_location,
                    sureCallback: this.locationCallback,
                },
            });
        },
        // 定位回调
        locationCallback(position, address) {
            this.formData.x_coordinate_point = `${position[0]}`;
            this.formData.y_coordinate_point = `${position[1]}`;
            this.formData.engineering_location = address;
            this.$forceUpdate();
        },
        // 企查查
        enterpriseInspection() {
            window.open('https://www.qcc.com/search?key=' + this.formData.client_name);
        },
        // 工程类型选中事件
        engineeringTypeChange(value) {
            const data = this.engineeringTypes.filter(item => item.name === value);
            this.formData.engineering_type_name = data[0].name;
            this.formData.engineering_type = data[0].code;
        },
        // 客户类型选中事件
        clientTypeChange(value) {
            const data = this.clientTypes.filter(item => item.code === value);
            this.formData.client_type_name = data[0].name;
            this.formData.client_type = data[0].code;
        },
        // 销售区域选中事件
        areaNameChange(value) {
            const data = this.saleAreas.filter(item => item.name === value);
            this.formData.area_name = data[0].name;
            this.formData.area_id = data[0].id;
        },
        // 支付方式选中事件
        paymentModeChange(value) {
            const data = this.paymentModes.filter(item => item.name === value);
            this.formData.payment_mode_name = data[0].name;
            this.formData.payment_mode = data[0].code;
        },
        // 强度等级选中事件
        strengthGradeChange(value) {
            const data = this.strengthGrades.filter(item => item.name === value);
            this.formData.strength_grade_name = data[0].name;
            this.formData.strength_grade = data[0].code;
        },
        // 施工部位选中事件
        castingPartsChange(value) {
            const data = this.castingParts.filter(item => item.name === value);
            this.formData.casting_parts_name = data[0].name;
            this.formData.casting_parts = data[0].code;
        },
        // 浇筑方式选中事件
        pouringWayChange(value) {
            const data = this.pouringWays.filter(item => item.name === value);
            this.formData.pouring_way_name = data[0].name;
            this.formData.pouring_way = data[0].code;
            if (['汽车泵', '车载泵', '固定泵'].indexOf(value) > -1) {
                this.volumePriceDisable = false;
            } else {
                this.volumePriceDisable = true;
                this.pump_supplement_price = 0;
            }
            if (['自卸', '吊斗', '转运', '工地汽车泵', '工地车载泵', '工地固定泵'].indexOf(value) > -1) {
                this.pumpTruckTypeDisable = true;
                this.formData.pump_truck_name = '';
                this.formData.pump_truck = '';
            } else {
                this.pumpTruckTypeDisable = false;
            }
        },
        // 泵车类型选中事件
        pumpTrucksChange(value) {
            const data = this.pumpTrucks.filter(item => item.name === value);
            this.formData.pump_truck_name = data[0].name;
            this.formData.pump_truck = data[0].code;
        },
        // 供应站点选中事件
        supplySiteChange(value) {
            const data = this.supplySites.filter(item => item.name === value);
            this.formData.supply_site_name = data[0].name;
            this.formData.supply_site = data[0].station_id;
        },
        // 获取供货站点下拉list
        async getStationList() {
            this.supplySites = [];
            const station_Names = this.$takeTokenParameters('StationNames').split(',');
            const station_IDs = this.$takeTokenParameters('StationIds').split(',');
            if ((station_Names.length || []) > 0) {
                station_Names.forEach((item, index) => {
                    this.supplySites.push({
                        name: item,
                        station_id: station_IDs[index],
                    });
                });
            }
        },
        // 获取销售区域下拉list
        async getSaleAreaList() {
            const res = await this.getSaleAreaListApi();
            if (res) {
                this.saleAreas = res;
            }
        },
        // 获取强度等级
        async getStrengthGrade() {
            this.strengthGrades = [];
            const strength1 = await this.getStrengthGradeApi('004');
            if (strength1) {
                for (let i = strength1.length - 1; i >= 0; i--) {
                    if (strength1[i].level === 3) {
                        this.strengthGrades.unshift(strength1[i]);
                    }
                }
            }
            const strength2 = await this.getStrengthGradeApi('005');
            if (strength2) {
                strength2.map(item => {
                    if (item.level === 3) {
                        this.strengthGrades.push(item);
                    }
                });
            }
        },
        // 选择供方单位
        selectSupplierUnit() {
            this.$toast({
                title: true,
                type: 'eject',
                width: '15rem',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'settlement_company_list',
                        sureCallBack: this.selectSupplierUnitCallBack,
                    },
                },
            });
        },
        // 选择供方单位回调
        selectSupplierUnitCallBack(data) {
            this.formData.org_name = data.org_name;
            this.formData.org_code = data.org_code;
            this.$forceUpdate();
        },

        // 选择自定义编号
        customMumberChange() {
            if (this.formData.is_customize_number === false) {
                this.formData.contract_number = '';
            }
        },

        // 获取下拉框数据列表
        async getCategory() {
            const engineeringTypes = await this.getCategoryApi('103');
            if (engineeringTypes) {
                this.engineeringTypes = engineeringTypes;
            }
            const clientTypes = await this.getCategoryApi('002-xz0');
            if (clientTypes) {
                this.clientTypes = clientTypes;
            }
            const paymentModes = await this.getCategoryApi('601-FK0');
            if (paymentModes) {
                this.paymentModes = paymentModes;
            }
            const castingParts = await this.getCategoryApi('105-ZT0');
            if (castingParts) {
                this.castingParts = castingParts;
            }
            const pouringWays = await this.getCategoryApi('302-JZ0');
            if (pouringWays) {
                this.pouringWays = pouringWays;
            }
            const pumpTrucks = await this.getCategoryApi('303-BS0');
            if (pumpTrucks) {
                this.pumpTrucks = pumpTrucks;
            }
        },

        // 客户名称建议列表数据
        async querySearch(queryString, cb) {
            const row = await this.getClients(queryString);
            // 调用 callback 返回建议列表的数据
            cb(row);
        },

        // 客户选择
        handleSelect(item) {
            this.formData.client_type = item.nature;
            this.formData.client_type_name = item.nature_name;
            this.formData.client_id = item.id;
            this.formData.client_name = item.name;
            this.formData.client_uscc = item.license_code;
            this.formData.client_contact_name = item.client_contact_name;
            this.formData.client_contact_phone = item.client_contact_phone;
        },

        // 获取流程模板信息
        getBusinessInstanceApi() {
            return this.$axios.$get('/interfaceApi/basicplatform/simpleflow/businessinstance/templateinfo/HTPS-LS2', { defEx: true });
        },
        // 添加零售业务台账
        addRetailApi(data) {
            return this.$axios.$post('/interfaceApi/sale/contract_manager/add_retail', data, { defEx: true });
        },
        // 获取字典数据
        getCategoryApi(parentCode) {
            return this.$axios.$get('/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=' + parentCode, { defEx: true });
        },
        // 获取销售区域
        getSaleAreaListApi() {
            return this.$axios.$get('/interfaceApi/sale/price_config/get_area_list', { defEx: true });
        },
        // 获取强度等级
        getStrengthGradeApi(parentCode) {
            return this.$axios.$get('/interfaceApi/baseinfo/dictionary_config/getlist_recursion?parent_code=' + parentCode, { defEx: true });
        },
        // 初始化订单编号
        getAutoCodeApi() {
            return this.$axios.$get('/interfaceApi/basicplatform/autocode/runtime?code=lsh_contract_code', { defEx: true });
        },
        // 获取零售业务台账明细
        getRetailDetailApi() {
            return this.$axios.$get('/interfaceApi/sale/contract_manager/get_retail_detailById/' + this.contractId, { defEx: true });
        },
        // 拉取客户信息列表
        getClients(Keywords) {
            return this.$axios.$get(`/interfaceApi/sale/client_manager/get_clients?Keywords=${Keywords}`, { defEx: true });
        },
        // 自定义编号验证特殊字符
        verifyCharacter() {
            // 规则对象(flag)
            const flag = new RegExp('[`~!@#$^&*=|\':;\',\\[\\].<>《》/?~！@#￥……&*（）|‘；：”“\'。，？]');
            // 判断 even 是否包含特殊字符
            if (flag.test(this.customMumber)) {
                return true;
            }
            return false;
        },
    },

};
</script>
// <style lang="stylus">
// .retailBusinessForm
//     height 100%
//     overflow-y auto
//     .table-container
//         width 100%
//         margin-bottom .2rem
//         .custom-table
//             border-spacing 0px
//             border-collapse collapse
//             width 100%
//             input::-webkit-outer-spin-button,
//             input::-webkit-inner-spin-button
//                 -webkit-appearance none !important
//             .pump-select
//                 .el-select
//                     width 1rem
//                     input
//                         border 1px solid #DCDFE6 !important
//                         height .32rem
//                         line-height .32rem
//                     .el-input__icon
//                         line-height .32rem!important
//             .text-center
//                 text-align center
//             tr
//                 background #F5F6F7
//             td
//                 width 5rem
//                 vertical-align top
//             td,th
//                 border-collapse collapse
//                 height .36rem
//                 line-height .36rem
//                 font-size .16rem
//                 padding 0.08rem
//                 input
//                     width 100%
//                     height 100%
//                     font-size 0.16rem
//                     height 0.36rem
//                     line-height 0.36rem
//                     border 1px solid #D0D0D0
//                 .NotAllowNullCss
//                     font-size .16rem
//                     color #D6000F
//                     font-weight 600
//                 .el-select
//                     width 100%
//                 .el-input
//                     .el-input-group__prepend,
//                     .el-input-group__append
//                         background none
//                         // border 1px solid #D0D0D0
//                         border-radius 2px 0 0 2px
//                         .iconfont
//                             font-size 0.2rem
//                 .el-radio-group
//                     padding-left 0.1rem
//                 .el-button
//                     margin-left 0.1rem
//                 .el-date-editor,
//                 .el-autocomplete
//                     width 100% !important
//                 .slump
//                     width 1rem
//                 .freightPrice
//                     width 1rem
//                     line-height .3rem
//                     border-radius .05rem
//                     input
//                         line-height .32rem!important
//                         height .32rem!important
//                         border 1px solid #D0D0D0
//                 .line-input
//                     width 1rem
//                     line-height .32rem
//                     border-radius .05rem
//                     input
//                         line-height .32rem!important
//                         height .32rem!important
//                         border 1px solid #D0D0D0
//                 .price-input
//                     width 1rem
//                     margin .05rem
//                     border-radius .05rem
//                     input
//                         width 1rem
//                         height .32rem
//                         border 1px solid #D0D0D0
//                 .businessNumber
//                     border 1px solid #D0D0D0
//                     display inline-block
//                     width 100%
//                     height 0.36rem
//                     vertical-align top
//             .table-tit
//                 background #fff
//                 color #022782
//                 font-weight bold
//                 text-align left
//             .column-tit
//                 text-align right
//                 color #022782
//                 line-height 0.18rem
//                 width 1.5rem
//                 vertical-align middle
// </style>
